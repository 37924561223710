import React from 'react';
import { Router } from '@reach/router';
import { view } from 'react-easy-state';
// import axios from 'axios';
import queryString from 'query-string';
import _ from 'lodash';
// import BarLoader from 'react-spinners/BarLoader';
// import Waypoint from 'react-waypoint';
import GoogleLogin from 'react-google-login';
import ls from 'store2';
import { Button } from 'antd';

import mainStore from '../stores';
import Layout from '../components/layout';
import VideoCard from '../components/video-card';

const lsYTT = ls.namespace('ytthumbs');

@view
class IndexRoute extends React.Component {
  async componentDidMount() {
    const { q } = queryString.parse(this.props.location.search);
    mainStore.keyword = q || '';

    const oauth = lsYTT('oauth');
    const tokenInfo = lsYTT('tokeninfo');

    mainStore.oauth = oauth;
    mainStore.tokenInfo = tokenInfo;

    // if (oauth) {
    //   await mainStore.loadPage();
    // }
  }

  // async componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.location.search === this.props.location.search) return;

  //   const { q } = queryString.parse(this.props.location.search);
  //   mainStore.keyword = q || '';
  //   await this.loadPage();
  // }

  render() {
    const {
      videos,
      nextPageToken,
      pageInfo,
      loading,
      oauth,
      tokenInfo,
      hidePrivate
    } = mainStore;

    const filtered = _.filter(videos, (video) => {
      return hidePrivate ? video.status.privacyStatus === 'public' : true;
    });

    return (
      <>
        {/* {pageInfo && <h2>Total: {pageInfo.totalResults}</h2>} */}
        {true && (
          <div css={tw`flex items-center`}>
            <div
              css={[tw`mr-3`, tokenInfo ? { display: 'none !important' } : null]}
            >
              <GoogleLogin
                clientId={process.env.GATSBY_YT_CLIENT_ID}
                buttonText="Login"
                scope="https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.force-ssl https://www.googleapis.com/auth/youtube.readonly"
                onSuccess={mainStore.responseGoogle}
                onFailure={mainStore.responseGoogle}
                accessType="offline"
                responseType="id_token code"
                prompt="consent"
                isSignedIn
              />
            </div>
            {tokenInfo && <span css={tw`text-sm font-bold`}>{tokenInfo.email}</span>}
          </div>
        )}


        <div css={[tw`flex flex-wrap`, { margin: '0 -0.75rem' }]}>
          {filtered && filtered.map((video, i) => (
            <VideoCard video={video} key={video.id} />
          ))}
        </div>

        {/* {videos.length > 0 && nextPageToken && (
          <Waypoint
            onEnter={() => this.loadPage(nextPageToken)}
            topOffset={500}
          />
        )} */}

        {videos.length > 0 && nextPageToken && (
          <div css={tw`mt-8 flex justify-center`}>
            <Button type="primary" size="large" loading={loading} onClick={() => mainStore.loadPage(nextPageToken)}>
              Load more
            </Button>
            {/* <BarLoader /> */}
            {/* <button
              type="button"
              css={
                !loading
                  ? tw`p-5 px-8 bg-green text-white text-2xl font-bold rounded cursor-pointer hover:bg-black`
                  : tw`p-5 px-8 bg-green text-white text-2xl font-bold rounded cursor-default opacity-25`
              }
              disabled={loading}
              onClick={() => mainStore.loadPage(nextPageToken)}
            >
              {!loading ? 'Load More' : 'loading...'}
            </button> */}
          </div>
        )}
      </>
    );
  }
}

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Router>
          <IndexRoute path="/" />
        </Router>
      </Layout>
    );
  }
}

export default view(IndexPage);
