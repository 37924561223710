import React from 'react';
import styled from '@emotion/styled';
import distanceInWords from 'date-fns/distance_in_words';
import format from 'date-fns/format';
import human from 'human-number';
import Clipboard from 'react-clipboard.js';
import slug from 'slug';
import { convertYouTubeDuration } from 'duration-iso-8601';
import _ from 'lodash';

import { colors } from '../../tailwind';

import ytCats from '../yt_categories.json';

const AspectRatio = styled.div`
  position: relative;

  &:before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc((9 / 16 * 100%) - 2px);
  }
  &:after {
    content: "";
    display: table;
    clear: both;
  }
`;

const Card = styled.div`
  padding: 0.25rem;

  .action, .bottom-action {
    transition: all .2s ease;
    opacity: 0;
    transform: translateY(100%);
  }

  .bottom-action {
    transform: translateY(-50%);
  }

  &:hover {
    /* background: ${colors['grey-light']}; */

    .action, .bottom-action {
      opacity: 1;
      transform: translateY(0);
    }

    .thumbnail {
      transform: scale(1.05);
    }
  }

  a {
    &:hover {
      color: ${colors.brand};
    }
  }
`;

export default class VideoCard extends React.Component {
  state = {
    showCopiedURL: false,
    showCopiedID: false,
    showCopiedSlug: false,
  }

  doneCopyingURL = () => {
    this.setState({ showCopiedURL: true });
    setTimeout(() => this.setState({ showCopiedURL: false }), 1500);
  }

  doneCopyingID = () => {
    this.setState({ showCopiedID: true });
    setTimeout(() => this.setState({ showCopiedID: false }), 1500);
  }

  doneCopyingSlug = () => {
    this.setState({ showCopiedSlug: true });
    setTimeout(() => this.setState({ showCopiedSlug: false }), 1500);
  }

  render() {
    const {
      videoId, snippet, statistics, contentDetails, status: { privacyStatus: status }
    } = this.props.video;

    const videoLink = `https://www.youtube.com/watch?v=${videoId}`;
    // const playlistLink = `https://www.youtube.com/playlist?list=${playlistId}`;

    const videoEditLink = `https://studio.youtube.com/video/${videoId}/edit`;
    // const playlistEditLink = `https://www.youtube.com/playlist?list=${playlistId}&disable_polymer=true`;

    const link = videoLink;
    const editLink = videoEditLink;
    const classicEditLink = `https://www.youtube.com/edit?video_id=${videoId}`;

    const category = _.find(ytCats, { id: parseInt(snippet.categoryId, 10) });

    return (
      <div css={tw`w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5`}>
        <Card>
          <div css={tw`flex mb-1`} className="action">
            {/* <a
              href={link}
              css={tw`py-1 px-1 text-xs font-bold rounded-sm cursor-pointer block ml-1`}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <i className="far fa-eye" />
            </a> */}
            <a
              href={editLink}
              css={tw`py-1 px-1 text-xs font-bold rounded-sm cursor-pointer block ml-1`}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <i className="fas fa-pen-square" />
            </a>
            <a
              href={classicEditLink}
              css={tw`py-1 px-1 text-xs font-bold rounded-sm cursor-pointer block ml-1`}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <i className="far fa-pen-square" />
            </a>

            <div css={tw`flex-1`} />

            <Clipboard data-clipboard-text={`${format(snippet.publishedAt, 'YYYYMMDD')}-${slug(snippet.title).toLowerCase()}-maxresdefault.jpg`} css={tw`mr-2`}>
              <a
                href={snippet.thumbnails.high.url.replace('hqdefault', 'maxresdefault')}
                css={[tw`text-xs font-bold cursor-pointer block ml-1`, {
                  padding: '0.2em 0.3em'
                }]}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                MAX
              </a>
            </Clipboard>
            <Clipboard data-clipboard-text={`${format(snippet.publishedAt, 'YYYYMMDD')}-${slug(snippet.title).toLowerCase()}-hqdefault.jpg`} css={tw`mr-2`}>
              <a
                href={snippet.thumbnails.high.url}
                css={tw`text-xs font-medium cursor-pointer block ml-1`}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                HQ
              </a>
            </Clipboard>
          </div>

          <AspectRatio>
            <a
              css={tw`block absolute w-full h-full`}
              href={link}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <img
                src={snippet.thumbnails.high.url}
                alt={snippet.title}
                className="thumbnail"
                css={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  transition: 'all 0.2s ease',
                }}
              />
              {contentDetails && (
                <div
                  css={[tw`absolute z-20 pin-b pin-r m-1 bg-black text-white text-2xs tracking-tight rounded-sm`, {
                    padding: '0em 0.3em'
                  }]}
                >
                  {convertYouTubeDuration(contentDetails.duration)}
                </div>
              )}
            </a>
          </AspectRatio>

          <div>
            <a
              css={tw`text-xs font-black tracking-tight leading-none inline-block mt-2`}
              href={link}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              {status !== 'public' && (
                <span
                  css={[tw`bg-black text-white inline-block rounded uppercase mr-1`, {
                    padding: '0.15em 0.3em',
                    fontSize: '0.85em'
                  }]}
                >
                  {status}
                </span>
              )}
              {/* {playlistId && !videoId && (
                <div
                  css={[tw`bg-black text-white inline-block rounded text-2xs uppercase mr-1`, {
                    padding: '0.2em 0.4em'
                  }]}
                >
                  Playlist
                </div>
              )} */}
              {snippet.title}
            </a>

            <div css={tw`text-xs font-bold mt-1 text-grey-darker`}>
              <i className="far fa-folder" />{' '}
              {category.name}
            </div>

            <div css={tw`text-xs text-grey-dark font-black tracking-tight flex flex-wrap justify-between mt-2`}>
              <span css={tw`mr-2`}>
                {distanceInWords(Date.now(), snippet.publishedAt)} ago
              </span>
              {statistics && (
                <span>
                  <i className="far fa-eye" css={tw`opacity-75`} />{' '}
                  {human(statistics.viewCount, n => parseFloat(n).toFixed(1)).replace(/\.0$/, '')}
                </span>
              )}
            </div>

            {videoId && (
              <div className="bottom-action" css={tw`flex flex-wrap justify-between text-2xs uppercase text-grey-dark mt-1`}>
                <div>
                  <Clipboard
                    data-clipboard-text={videoLink}
                    onSuccess={this.doneCopyingURL}
                    css={tw`mr-2`}
                  >
                    <>
                      {!this.state.showCopiedURL ? (
                        <span>Copy URL</span>
                      ) : (
                        <span css={tw`text-2xs uppercase font-bold`}>copied!</span>
                      )}
                    </>
                  </Clipboard>
                </div>
                <div>
                  <Clipboard
                    data-clipboard-text={videoId}
                    onSuccess={this.doneCopyingID}
                    css={tw`mr-2`}
                  >
                    <>
                      {!this.state.showCopiedID ? (
                        <span>Copy ID</span>
                      ) : (
                        <span css={tw`text-2xs uppercase font-bold`}>copied!</span>
                      )}
                    </>
                  </Clipboard>
                </div>
                <div>
                  <Clipboard
                    data-clipboard-text={`${format(snippet.publishedAt, 'YYYYMMDD')}-${slug(snippet.title).toLowerCase()}`}
                    onSuccess={this.doneCopyingSlug}
                    css={tw``}
                  >
                    <>
                      {!this.state.showCopiedSlug ? (
                        <span>Copy Slug</span>
                      ) : (
                        <span css={tw`text-2xs uppercase font-bold`}>copied!</span>
                      )}
                    </>
                  </Clipboard>
                </div>
              </div>
            )}
          </div>
        </Card>
      </div>
    );
  }
}
